import { StateCreator } from "zustand";

export interface PortfolioAnalyticsSlice {
  selectedAnalyticsPortfolios: string[];
  selectedAnalyticsBoards: string[];
  selectedBoardJoiningYear: string;
  setSelectedBoardJoiningYear: (year: string) => void;
  setSelectedAnalyticsPortfolios: (portfolios: string[]) => void;
  setSelectedAnalyticsBoards: (boards: string[]) => void;
  resetPortfolioAnalytics: () => void;
}

const initialState = {
  selectedAnalyticsPortfolios: [],
  selectedAnalyticsBoards: [],
  selectedBoardJoiningYear: "",
};

const createPortfolioAnalyticsSlice: StateCreator<PortfolioAnalyticsSlice> = (
  set
) => ({
  ...initialState,
  setSelectedAnalyticsPortfolios: (portfolios) =>
    set(() => ({ selectedAnalyticsPortfolios: portfolios })),
  setSelectedAnalyticsBoards: (boards) =>
    set(() => ({ selectedAnalyticsBoards: boards })),
  setSelectedBoardJoiningYear: (year) =>
    set(() => ({ selectedBoardJoiningYear: year })),
  resetPortfolioAnalytics: () => set(() => initialState),
});

export default createPortfolioAnalyticsSlice;
