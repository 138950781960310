export enum ResponderStatus {
  AWAITING_RESPONSE = "AWAITING_RESPONSE",
  COMPLETED = "COMPLETED",
}

export enum PortfolioEditorStatus {
  QR_CREATED = "QR_CREATED",
  CREATED = "CREATED",
  SHARED = "SHARED",
}
