import ArborAppBar from "@/components/appbars/ArborAppBar";
import ArborDrawer from "@/components/drawers/ArborDrawer";
import { styled } from "@mui/material/styles";

export type DefaultPageLayoutProps = React.PropsWithChildren<{ title: string }>;

const drawerWidth = 256;
const PageContainer = styled("div")(({}) => ({
  display: "flex",
  backgroundColor: "#FBFDF8",
  minHeight: "100vh",
  paddingInlineStart: `${drawerWidth}px`,
  paddingBlockStart: "94px",
}));
const DefaultPageLayout = ({ children, title }: DefaultPageLayoutProps) => {
  return (
    <>
      <ArborAppBar title={title} />
      <ArborDrawer />
      <PageContainer>{children}</PageContainer>
    </>
  );
};

export default DefaultPageLayout;
