import Drawer from "@mui/material/Drawer";
import { theme } from "@/theme/theme";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import ArborLogoLight from "src/assets/arbor-logo-alt.png";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SquareFootIcon from "@/assets/square-foot.svg";
import SurveyIcon from "@/assets/survey.svg";
import HubspotIcon from "@/assets/hubspot.svg";
import SettingsIcon from "@/assets/settings.svg";
import DefaultAvatar from "@/assets/default-avatar.svg";
import MagicWandIcon from "@/assets/magic-wand.svg";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ROUTES } from "@/constants/routes";
import { useRouter } from "next/router";
import { useAuth } from "@/contexts/AuthContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Collapse from "@mui/material/Collapse";
import useStore from "@/stores/store";
import Link from "next/link";
import { useQueryClient } from "@tanstack/react-query";

const drawerWidth = 256;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBlockStart: 16,
  marginBlockEnd: 8,
  marginLeft: 16,
  ...theme.mixins.toolbar,
}));

const ArborDrawer = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { signout, user } = useAuth();
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [openAnalytics, setOpenAnalytics] = useState(true);
  const [openPortfolioAnalytics, setOpenPortfolioAnalytics] = useState(true);
  const [openCollect, setOpenCollect] = useState(true);
  const [openPortfolio, setOpenPortfolio] = useState(true);
  const [openCompany, setOpenCompany] = useState(true);
  const profileMenuOpen = Boolean(profileMenuAnchorEl);
  const companyDrawerSettings = useStore(
    (state) =>
      state.companySettings?.find((setting) => setting.name === "DRAWER")?.value
  );
  const handleProfileMenuClick = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <DrawerHeader>
        <Link href={ROUTES.ROOT}>
          <Image
            src={ArborLogoLight}
            alt="arbor-logo-light"
            height={64}
            priority
          />
        </Link>
      </DrawerHeader>

      <Divider />
      <List>
        {companyDrawerSettings?.collect && (
          <>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2,
              }}
              onClick={() => setOpenCollect(!openCollect)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,

                  justifyContent: "center",
                  color: "white",
                }}
              >
                <IconButton>
                  <Image
                    src={SurveyIcon}
                    alt="collect"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "medium",
                  variant: "body1",
                  pl: 2,
                }}
                primary={"Collect"}
                sx={{ color: "white" }}
              ></ListItemText>
              {openCollect ? (
                <ArrowDropUpIcon htmlColor="white" />
              ) : (
                <ArrowDropDownIcon htmlColor="white" />
              )}
            </ListItemButton>
            <Collapse in={openCollect} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {companyDrawerSettings?.collect.map((setting) => {
                  return (
                    <ListItemButton
                      onClick={() => router.push(`/${setting.type}`)}
                      key={`${setting.name}-${setting.type}`}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText
                        primary={setting.name}
                        sx={{ color: "white" }}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </>
        )}
        {companyDrawerSettings?.analytics && (
          <>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2,
              }}
              onClick={() => setOpenAnalytics(!openAnalytics)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,

                  justifyContent: "center",
                  color: "white",
                }}
              >
                <IconButton>
                  <Image
                    src={HubspotIcon}
                    alt="analytics"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "medium",
                  variant: "body1",
                  pl: 2,
                }}
                primary={"Analytics"}
                sx={{ color: "white" }}
              ></ListItemText>
              {openAnalytics ? (
                <ArrowDropUpIcon htmlColor="white" />
              ) : (
                <ArrowDropDownIcon htmlColor="white" />
              )}
            </ListItemButton>
            <Collapse in={openAnalytics} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {companyDrawerSettings?.analytics.map((setting) => {
                  if (setting.type === "engagement") {
                    return (
                      <ListItemButton
                        onClick={() =>
                          router.push(
                            `${ROUTES.PORTFOLIO_ANALYTICS}/${setting.type}/${setting.instance_id}`
                          )
                        }
                        key={`${setting.type}-${setting.instance_id}`}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText
                          primary={setting.name}
                          sx={{ color: "white" }}
                        />
                      </ListItemButton>
                    );
                  }
                  return (
                    <ListItemButton
                      onClick={() =>
                        router.push(`${ROUTES.ANALYTICS}/${setting.type}`)
                      }
                      key={`${setting.type}`}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText
                        primary={setting.name}
                        sx={{ color: "white" }}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </>
        )}
        {companyDrawerSettings?.portfolio_analytics && (
          <>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2,
              }}
              onClick={() => setOpenPortfolioAnalytics(!openPortfolioAnalytics)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,

                  justifyContent: "center",
                  color: "white",
                }}
              >
                <IconButton>
                  <Image
                    src={HubspotIcon}
                    alt="analytics"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "medium",
                  variant: "body1",
                  pl: 2,
                }}
                primary={"Portfolio Analytics"}
                sx={{ color: "white" }}
              ></ListItemText>
              {openPortfolioAnalytics ? (
                <ArrowDropUpIcon htmlColor="white" />
              ) : (
                <ArrowDropDownIcon htmlColor="white" />
              )}
            </ListItemButton>
            <Collapse in={openPortfolioAnalytics} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {companyDrawerSettings?.portfolio_analytics.map((setting) => {
                  return (
                    <ListItemButton
                      onClick={() =>
                        router.push(
                          `${ROUTES.PORTFOLIO_ANALYTICS}/${setting.type}/${setting.instance_id}`
                        )
                      }
                      key={`${setting.type}-${setting.instance_id}`}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText
                        primary={setting.name}
                        sx={{ color: "white" }}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </>
        )}
        {companyDrawerSettings?.portfolio && (
          <>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2,
              }}
              onClick={() => setOpenPortfolio(!openPortfolio)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,

                  justifyContent: "center",
                  color: "white",
                }}
              >
                <IconButton>
                  <Image
                    src={HubspotIcon}
                    alt="analytics"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "medium",
                  variant: "body1",
                  pl: 2,
                }}
                primary={"Portfolio"}
                sx={{ color: "white" }}
              ></ListItemText>
              {openPortfolio ? (
                <ArrowDropUpIcon htmlColor="white" />
              ) : (
                <ArrowDropDownIcon htmlColor="white" />
              )}
            </ListItemButton>
            <Collapse in={openPortfolio} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {companyDrawerSettings?.portfolio.map((setting) => {
                  return (
                    <ListItemButton
                      onClick={() =>
                        router.push(
                          `${ROUTES.PORTFOLIO_ANALYTICS}/${setting.type}/${setting.instance_id}`
                        )
                      }
                      key={`${setting.type}-${setting.instance_id}`}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText
                        primary={setting.name}
                        sx={{ color: "white" }}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </>
        )}
        {companyDrawerSettings?.company && (
          <>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2,
              }}
              onClick={() => setOpenCompany(!openCompany)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,

                  justifyContent: "center",
                  color: "white",
                }}
              >
                <IconButton>
                  <Image
                    src={HubspotIcon}
                    alt="company"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "medium",
                  variant: "body1",
                  pl: 2,
                }}
                primary={"Company"}
                sx={{ color: "white" }}
              ></ListItemText>
              {openCompany ? (
                <ArrowDropUpIcon htmlColor="white" />
              ) : (
                <ArrowDropDownIcon htmlColor="white" />
              )}
            </ListItemButton>
            <Collapse in={openCompany} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {companyDrawerSettings?.company.map((setting) => {
                  if (setting.type === "engagement") {
                    return (
                      <ListItemButton
                        onClick={() =>
                          router.push(
                            `${ROUTES.PORTFOLIO_ANALYTICS}/${setting.type}/${setting.instance_id}`
                          )
                        }
                        key={`${setting.type}-${setting.instance_id}`}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText
                          primary={setting.name}
                          sx={{ color: "white" }}
                        />
                      </ListItemButton>
                    );
                  }
                  if (setting.type === "collect/employee-overview") {
                    return (
                      <ListItemButton
                        onClick={() => router.push(`/${setting.type}`)}
                        key={`${setting.name}-${setting.type}`}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText
                          primary={setting.name}
                          sx={{ color: "white" }}
                        />
                      </ListItemButton>
                    );
                  }
                  return (
                    <ListItemButton
                      onClick={() =>
                        router.push(`${ROUTES.ANALYTICS}/${setting.type}`)
                      }
                      key={`${setting.type}`}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText
                        primary={setting.name}
                        sx={{ color: "white" }}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </>
        )}
        {companyDrawerSettings?.benchmark && (
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2,
            }}
            onClick={() => router.push(ROUTES.BENCHMAKRS)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,

                justifyContent: "center",
                color: "white",
              }}
            >
              <IconButton>
                <Image
                  src={SquareFootIcon}
                  alt="benchmark"
                  width={20}
                  height={20}
                />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontWeight: "medium",
                variant: "body1",
                pl: 2,
              }}
              primary={"Benchmarks"}
              sx={{ color: "white" }}
            ></ListItemText>
          </ListItemButton>
        )}
      </List>
      <List
        sx={{
          flexGrow: 2,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <ListItem sx={{ display: "block" }} disablePadding>
          {companyDrawerSettings?.integrations && (
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2,
              }}
              onClick={() => router.push(ROUTES.INTEGRATIONS)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,

                  justifyContent: "center",
                  color: "white",
                }}
              >
                <IconButton>
                  <Image
                    src={MagicWandIcon}
                    alt="integrations"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "medium",
                  variant: "body1",
                  pl: 2,
                }}
                primary={"Integrations"}
                sx={{ color: "white" }}
              ></ListItemText>
            </ListItemButton>
          )}
          <ListItemButton
            sx={{
              minHeight: 48,

              px: 2,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: "center",
                color: "white",
              }}
            >
              <IconButton>
                <Image
                  src={DefaultAvatar}
                  alt="random-photo"
                  width={24}
                  height={24}
                />
              </IconButton>
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{
                fontWeight: "medium",
                variant: "body1",
                ml: -1.5,
              }}
              primary={user?.displayName ?? "John Doe"}
              sx={{ color: "white", mr: 3 }}
            ></ListItemText>
            <ListItemIcon
              sx={{
                minWidth: 0,

                color: "white",
              }}
            >
              <IconButton sx={{ mr: -1.5 }} onClick={handleProfileMenuClick}>
                <Image
                  src={SettingsIcon}
                  alt="settings"
                  width={20}
                  height={20}
                />
              </IconButton>
              <Menu
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorEl={profileMenuAnchorEl}
              >
                <MenuItem
                  onClick={() => {
                    queryClient.clear();
                    useStore.getState().reset();
                    signout();
                  }}
                >
                  Sign Out
                </MenuItem>
              </Menu>
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default ArborDrawer;
