import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { theme } from "@/theme/theme";
import useMediaQuery from "@mui/material/useMediaQuery";

type ArborAppBarProps = {
  title: string;
  hasDrawer?: boolean;
};

const drawerWidth = 256;

const ArborAppBar = ({ title, hasDrawer = true }: ArborAppBarProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${hasDrawer ? drawerWidth : 0}px)`,
        borderBottom: "0.5px solid #717971",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        paddingInline: "48px",
      }}
      elevation={0}
    >
      <Toolbar sx={{ height: "94px" }}>
        <Typography
          variant={isMobile ? "h6" : "h4"}
          component="div"
          sx={{ fontWeight: 400, flexGrow: 1 }}
        >
          {title}
        </Typography>
        {/* <Button color="inherit">Login</Button> */}
      </Toolbar>
    </AppBar>
  );
};

export default ArborAppBar;
