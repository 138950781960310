import { create } from "zustand";
import createSurveySlice, { SurveySlice } from "./survey-slice";
import createUserSlice, { UserSlice } from "./user-slice";
import { persist } from "zustand/middleware";
import createCompanySlice, { CompanySlice } from "./company-slice";
import createPortfolioAnalyticsSlice, {
  PortfolioAnalyticsSlice,
} from "./portfolio-analytics-slice";
import createFeedbackSlice, { FeedbackSlice } from "./feedback-slice";
import createHRISDataSlice, { HRISDataSlice } from "./hris-data-slice";

export interface GlobalState {
  reset: () => void;
}

const useStore = create<
  GlobalState &
    SurveySlice &
    UserSlice &
    CompanySlice &
    PortfolioAnalyticsSlice &
    FeedbackSlice &
    HRISDataSlice
>()(
  persist(
    (...a) => ({
      ...createSurveySlice(...a),
      ...createUserSlice(...a),
      ...createCompanySlice(...a),
      ...createPortfolioAnalyticsSlice(...a),
      ...createFeedbackSlice(...a),
      ...createHRISDataSlice(...a),
      reset: () => {
        createSurveySlice(...a).resetSurvey();
        createUserSlice(...a).resetUser();
        createCompanySlice(...a).resetCompany();
        createPortfolioAnalyticsSlice(...a).resetPortfolioAnalytics();
        createFeedbackSlice(...a).resetFeedback();
        createHRISDataSlice(...a).resetHRISData();
      },
    }),
    {
      name: "arbor-fundfolio-store",
    }
  )
);

export default useStore;
