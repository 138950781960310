import { ResponderStatus } from "@/constants/surveystatus";
import { StateCreator } from "zustand";

type Option = {
  id: string;
  option: string;
  response: string | boolean;
};

type Question = {
  id: string;
  question: string;
  question_order: number;
  type: string;
  options: Option[];

  instructions: string[];
  opt_out: boolean;
  opt_out_reason: string;
  isOptional: boolean;
};

type Survey = {
  id: string;
  title: string;
  description: string;
  questions: Question[];
};

const groupByCategory: any = (questions) => {
  const grouped = {};

  // Group questions by category and keep track of category details
  questions.forEach((question) => {
    const category = question.QuestionCategory.category;
    const categoryOrder = question.QuestionCategory.category_order;
    const categoryDescription = question.QuestionCategory.description;

    if (!grouped[category]) {
      grouped[category] = {
        category,
        categoryOrder,
        categoryDescription,
        questions: [],
      };
    }
    grouped[category].questions.push(question);
  });

  // Sort each category's questions by question_order
  for (const category in grouped) {
    grouped[category].questions.sort(
      (a, b) => a.question_order - b.question_order
    );
  }

  const sortedGrouped = Object.values(grouped).sort(
    (a: any, b: any) => a.categoryOrder - b.categoryOrder
  );

  return sortedGrouped;
};

export interface SurveySlice {
  activeSurveyStep: number;
  selectedSurveyInstanceId: string;
  selectedQuestions: any;
  selectedPortfolioQuestions: any;
  selectedAdditionalQuestions: any;
  selectedAccordions: any;
  setSelectedQuestions: (questions: any) => void;
  updateSelectedAdditionalQuestions: (
    portfolioId: string,
    questionId: string
  ) => void;
  setSelectedAccordions: (accordions: any) => void;
  selectedSurvey: Survey | null;
  selectedSurveyTitle: string;
  selectedSurveyDescription: string;
  selectedSurveyStatus: {
    status: ResponderStatus;
    id: string;
    updatedAt: string;
    createdAt: string;
  };
  selectedPortfolios: string[];
  companyPortfolios: any[];
  categories: any[];
  setSelectedSurvey: (survey: Survey) => void;
  setSelectedPortfolios: (portfolios: string[]) => void;
  setCompanyPortfolios: (portfolios: any[]) => void;
  setSelectedSurveyTitle: (title: string) => void;
  setSelectedSurveyDescription: (description: string) => void;
  setSelectedSurveyStatus: (status: {
    status: ResponderStatus;
    id: string;
    updatedAt: string;
    createdAt: string;
  }) => void;
  resetSurvey: () => void;
  setCategories: (categories: string[]) => void;
  updateSurveyResponsesByCategory: (
    categoryName: string,
    updatedResponses: any
  ) => void;
  updateC2CSurveyData: (data: any) => void;
  updateC2CMSurveyData: (data: any) => void;
  setSelectedSurveyInstanceId: (id: string) => void;
  setSurveyActiveStep: (step: number) => void;
  updateC2C2MPortfolioSurveyData: (data: any) => void;
}

const initialState = {
  activeSurveyStep: -2,
  selectedSurvey: null,
  selectedSurveyInstanceId: "",
  selectedPortfolios: [],
  categories: [],
  selectedSurveyTitle: "",
  selectedSurveyDescription: "",
  selectedQuestions: {},
  selectedPortfolioQuestions: {},
  selectedAdditionalQuestions: {},
  selectedAccordions: {},
  companyPortfolios: [],
  selectedSurveyStatus: {
    status: ResponderStatus.AWAITING_RESPONSE,
    id: "",
    updatedAt: "",
    createdAt: "",
  },
};

const createSurveySlice: StateCreator<SurveySlice> = (set) => ({
  ...initialState,
  setSelectedSurveyInstanceId: (id) =>
    set(() => ({ selectedSurveyInstanceId: id })),
  setSelectedSurveyStatus: (status) =>
    set(() => ({ selectedSurveyStatus: status })),
  setSelectedQuestions: (newSelectedQuestions) =>
    set({ selectedQuestions: newSelectedQuestions }),
  setSelectedSurvey: (survey) => set(() => ({ selectedSurvey: survey })),
  setSelectedPortfolios: (portfolios) =>
    set(() => ({ selectedPortfolios: portfolios })),
  setSelectedSurveyTitle: (title) =>
    set(() => ({ selectedSurveyTitle: title })),
  setSelectedSurveyDescription: (description) =>
    set(() => ({ selectedSurveyDescription: description })),
  resetSurvey: () => set(() => initialState),
  setCategories: (categories) => set(() => ({ categories })),
  setSelectedAccordions: (accordions) =>
    set(() => ({ selectedAccordions: accordions })),
  setCompanyPortfolios: (portfolios) =>
    set(() => ({ companyPortfolios: portfolios })),
  updateSurveyResponsesByCategory: (categoryName, updatedResponses) =>
    set((state) => {
      const updatedQuestions = state.selectedSurvey.questions.map(
        (question) => {
          if (question["QuestionCategory"].category === categoryName) {
            const updatedQuestion = updatedResponses.find(
              (q) => q.id === question.id
            );
            return updatedQuestion
              ? {
                  ...question,
                  options: updatedQuestion.options,
                  opt_out: updatedQuestion.opt_out,
                  opt_out_reason: updatedQuestion.opt_out_reason,
                }
              : question;
          }
          return question;
        }
      );
      return {
        selectedSurvey: {
          ...state.selectedSurvey,
          questions: updatedQuestions,
        },
      };
    }),
  updateC2CSurveyData: (data: any) => {
    const categories = groupByCategory(data.survey.questions); // Assuming groupByCategory is defined
    const questions = categories.reduce((acc, category) => {
      acc[category.category] = category.questions.map((q) => q.id);
      return acc;
    }, {});
    const initialStates = categories.reduce((acc, category) => {
      acc[category.category] = true;
      return acc;
    }, {});
    set((prevState) => {
      return {
        ...prevState,
        selectedSurvey: data.survey,
        selectedSurveyTitle: data.SurveyInstanceInfo.editor_title,
        selectedSurveyDescription: data.SurveyInstanceInfo.editor_description,
        selectedQuestions: questions,
        selectedAccordions: initialStates,
        selectedPortfolios: [],
      };
    });
  },
  updateC2CMSurveyData: (data: any) => {
    const questions = {
      Questions: data.survey.questions
        .filter((q) => q.visibility === "BASELINE")
        .sort((a, b) => a.question_order - b.question_order),
      AdditionalQuestions: data.survey.questions
        .filter((q) => q.visibility === "ADDITIONAL")
        .sort((a, b) => a.question_order - b.question_order),
    };
    set((prevState) => {
      return {
        ...prevState,
        selectedSurvey: data.survey,
        selectedSurveyTitle: data.SurveyInstanceInfo.editor_title,
        selectedSurveyDescription: data.SurveyInstanceInfo.editor_description,
        selectedQuestions: questions,
        //selectedAccordions: initialStates,
        selectedPortfolios: [],
      };
    });
  },
  updateC2C2MPortfolioSurveyData: (data: any) => {
    const baseLineQuestions = data.BaselineQuestion.map(
      (q) => q.questionId
    ).concat(data.AdditionalQuestion.map((q) => q.questionId));
    const questions = {
      Questions: data.survey.questions.filter((q) =>
        baseLineQuestions.includes(q.id)
      ),

      AdditionalQuestions: data.survey.questions.filter(
        (q) =>
          q.visibility === "ADDITIONAL" && !baseLineQuestions.includes(q.id)
      ),
    };
    set((prevState) => {
      return {
        ...prevState,
        selectedSurvey: data.survey,
        selectedSurveyTitle: data.SurveyInstanceInfo.editor_title,
        selectedSurveyDescription: data.SurveyInstanceInfo.editor_description,
        selectedPortfolioQuestions: questions,
        //selectedAccordions: initialStates,
        selectedPortfolios: [],
      };
    });
  },

  setSurveyActiveStep: (step) => set(() => ({ activeSurveyStep: step })),
  updateSelectedAdditionalQuestions: (portfolioId, questionId) => {
    set((state) => {
      const updatedSelected = { ...state.selectedAdditionalQuestions };
      if (updatedSelected[questionId]) {
        const index = updatedSelected[questionId].indexOf(portfolioId);
        if (index !== -1) {
          updatedSelected[questionId] = updatedSelected[questionId].filter(
            (id) => id !== portfolioId
          );
        } else {
          updatedSelected[questionId].push(portfolioId);
        }
      } else {
        updatedSelected[questionId] = [portfolioId];
      }

      if (
        updatedSelected[questionId] &&
        updatedSelected[questionId].length === 0
      ) {
        delete updatedSelected[questionId];
      }

      return { selectedAdditionalQuestions: updatedSelected };
    });
  },
});

export default createSurveySlice;
