import { StateCreator } from "zustand";

type DropdownData = {
  seniority: { label: string; value: string }[];
  departments: { label: string; value: string }[];
  divisions: { label: string; value: string }[];
  locations: { label: string; value: string }[];
};

export interface HRISDataSlice {
  hris_drowdown: DropdownData;
  setHRISDropdown: (data: DropdownData) => void;
  resetHRISData: () => void;
}

const initialState = {
  hris_drowdown: {
    seniority: [],
    departments: [],
    divisions: [],
    locations: [],
  },
};

const createHRISDataSlice: StateCreator<HRISDataSlice> = (set) => ({
  ...initialState,
  setHRISDropdown: (data) =>
    set((state) => ({
      hris_drowdown: {
        ...state.hris_drowdown,
        ...data,
      },
    })),
  resetHRISData: () => set(() => initialState),
});

export default createHRISDataSlice;
