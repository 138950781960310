import { StateCreator } from "zustand";

export interface CompanySlice {
  companySettings: any;
  setCompanySettings: (companySettings: any) => void;
  resetCompany: () => void;
}

const initialState = {
  companySettings: null,
};

const createCompanySlice: StateCreator<CompanySlice> = (set) => ({
  ...initialState,
  setCompanySettings: (companySettings) => set(() => ({ companySettings })),
  resetCompany: () => set(() => initialState),
});

export default createCompanySlice;
