import { styled } from "@mui/material/styles";

const PageWrapper = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FBFDF8",
  paddingInline: "72px",
  paddingBlockStart: "32px",
  width: "100%",
}));

export default PageWrapper;
