import { StateCreator } from "zustand";

type Company = {
  id: string;
  name: string;
  fundedBy: {
    id: string;
    name: string;
  }[];
};

type User = {
  id: string;
  uid: string;
  name: string;
  email: string;
  company: Company;
};

export interface UserSlice {
  user: User | null;
  setUser: (user: User) => void;
  resetUser: () => void;
}

const initialState = {
  user: null,
};

const createUserSlice: StateCreator<UserSlice> = (set) => ({
  ...initialState,
  setUser: (user) => set(() => ({ user })),
  resetUser: () => set(() => initialState),
});

export default createUserSlice;
